import { ReduxState } from 'ra-core'
export interface IRequestData {
  url: string
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET'
  data?: any
  token?: string
  host?: string
}

export interface IResponse {
  data: any
  err: any
}

export interface BaseAction {
  type: string
  payload: any
}

export type ThemeName = 'light' | 'dark'

export interface AppState extends ReduxState {

}
export const AdminRoles = [
  {id: 'admin', name: 'Администратор'},
  {id: 'restaurantManager', name: 'Ресторан менеджер'},
  {id: 'manager', name: 'Контент менеджер'}
]
export interface IUser {
  id: number
  name: string
}

export enum CountryCode {
  Ru = 'ru'
}



export const PushTypes = [
  { id: 'admin', name: 'Общее уведомление' },
  { id: 'userRegistered', name: 'Пользователь зарегистрирован' }
]
export const PushNotificationChannel = [
  { id: 'push', name: 'Push' },
  { id: 'email', name: 'Email' },
  { id: 'alert', name: 'Уведомление' }
]
export const PushTargetTypes = [
  { id: 'all', name: 'Всем' },
  { id: 'user', name: 'Пользователю' }
]
export const PushStatuses = [
  { id: 'created', name: 'Черновик' },
  { id: 'toSent', name: 'Отправить' },
]
export const PushPriorities = [
  { id: 'high', name: 'Высокий' },
  { id: 'normal', name: 'Обычный' },
]
export const PushSoundTypes = [
  { id: '', name: 'Отключен' },
  { id: 'default', name: 'Включен' },
]

export enum FeedbackStatus {
  Moderation = 'moderation',
  Approved = 'approved',
  Rejected = 'rejected',
}
export const FeedbackStatusList = [
  { id: FeedbackStatus.Moderation, name: 'Модерация' },
  { id: FeedbackStatus.Approved, name: 'Запрувлен' },
  { id: FeedbackStatus.Rejected, name: 'Отклонен' },
]

export const FeedbackMarkList = [
  { id: 0, name: '0' },
  { id: 1, name: '2' },
  { id: 2, name: '3' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
]



export enum ReservationType {
  Table = 'table',
  Banquet = 'banquet',
}

export const ReservationTypeList = [
  { id: ReservationType.Table, name: 'Стол' },
  { id: ReservationType.Banquet, name: 'Банкет' }
]


export enum ReservationStatus {
  New = 'new',
  InProgress = 'inProgress',
  Approved = 'approved',
  Rejected = 'rejected',
  ClientCanceled = 'clientCanceled',
  Finished = 'finished',
}

export const ReservationStatusList = [
  { id: ReservationStatus.New, name: 'Новый' },
  { id: ReservationStatus.InProgress, name: 'В работе' },
  { id: ReservationStatus.Approved, name: 'Одобрен' },
  { id: ReservationStatus.Rejected, name: 'Отклонен' },
  { id: ReservationStatus.ClientCanceled, name: 'Отменен клиентов' },
  { id: ReservationStatus.Finished, name: 'Посещение состоялось' },
]


export enum ArticleType {
  News = 'news',
  Article = 'article',
  EntityList = 'entityList',
}
export enum ArticleEntityListType {
  Events = 'events',
  Restaurants = 'restaurants',
  Entertainment = 'entertainments',
  Property = 'properties',
  Boat = 'boats',
  Hotel = 'hotels',
}

export const ArticleTypeList = [
  { id: ArticleType.News, name: 'Новость' },
  { id: ArticleType.Article, name: 'Статья' },
  { id: ArticleType.EntityList, name: 'Список' }
]
export const ArticleEntityListTypeList = [
  { id: ArticleEntityListType.Restaurants, name: 'Рестораны' },
  { id: ArticleEntityListType.Entertainment, name: 'Экскурсии' },
  { id: ArticleEntityListType.Events, name: 'События' },
  { id: ArticleEntityListType.Property, name: 'Недвижимость' },
  { id: ArticleEntityListType.Hotel, name: 'Гостиницы' },
  { id: ArticleEntityListType.Boat, name: 'Лодки' }
]

export const ArticleGroupTypeList = [
  { id: 'lifestyle', name: 'LifeStyle' },
  { id: 'business', name: 'Бизнес' },
  { id: 'news', name: 'Новости' }
]

export enum AverageBillType {
  Low = 1,
  Normal = 2,
  Expensive = 3,
}
export const AverageBillTypeList = [
  { id: AverageBillType.Low, name: '₽' },
  { id: AverageBillType.Normal, name: '₽₽' },
  { id: AverageBillType.Expensive, name: '₽₽₽' }
]

export enum BannerType {
  UnSkippable = 'unSkippable',
  Top = 'top',
}
export const BannerTypeList = [
  { id: BannerType.UnSkippable, name: 'Не скрываемый' },
  { id: BannerType.Top, name: 'Верхний' },
]
export enum BannerViewType {
  Custom = 'custom',
  Events = 'events',
  Restaurants = 'restaurants',
  Entertainment = 'entertainments',
  Property = 'properties',
  Boat = 'boats',
  Hotel = 'hotels',
}
export const BannerViewTypeList = [
  { id: BannerViewType.Custom, name: 'Кастомный' },
  { id: BannerViewType.Restaurants, name: 'Ресторан' },
  { id: BannerViewType.Entertainment, name: 'Экскурсия' },
  { id: BannerViewType.Events, name: 'Событие' },
  { id: BannerViewType.Property, name: 'Недвижимость' },
  { id: BannerViewType.Hotel, name: 'Гостиница' },
  { id: BannerViewType.Boat, name: 'Лодка' }
]

export enum PropertyType {
  Boat = 'boat',
  Transport = 'transport',
  Property = 'property',
  Hotel = 'hotel',
}


export enum RestaurantNewsType {
  News = 'news',
  Article = 'article'
}

export const RestaurantNewsTypeList = [
  { id: RestaurantNewsType.Article, name: 'Статья' },
  { id: RestaurantNewsType.News, name: 'Новость' },

]


export enum HotelNewsType {
  News = 'news',
  Article = 'article'
}

export const HotelNewsTypeList = [
  { id: HotelNewsType.Article, name: 'Статья' },
  { id: HotelNewsType.News, name: 'Новость' },

]
export const ReservationDurationList = Array.from({length: 30}, (_, i) => i + 1).map(i => i * 30).map( i => ({id: i, name: `${i}`}))