import * as React from 'react';
import {FC, useRef, useState} from 'react';
import keyBy from 'lodash/keyBy'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  useGetOne,
  required,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput, ReferenceArrayInput, AutocompleteArrayInput, useRefresh, useDataProvider, NumberInput,
} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import GridListTile from '@material-ui/core/GridListTile'
import Typography from '@material-ui/core/Typography'
import {Button} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel';
import FocalPoint from 'src/components/fields/FocalPoint'
import {FocalPointInput} from 'src/components/inputs/FocalPointInput'
import ModalForm from 'src/components/ModalForm'
import {getMediaPath} from 'src/utils/media'

interface ProductTitleProps {
  record?: any;
}

const ProductTitle: FC<ProductTitleProps> = ({record}) => <span>Файл {record.name}</span>

const useStyles = makeStyles({
  root:{
  },
  imageView:{
    background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACuoAAArqAVDM774AAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTZEaa/1AAAAK0lEQVQ4T2P4jwP8xgFGNSADqDwGIF0DlMYAUH0YYFQDMoDKYwASNfz/DwB/JvcficphowAAAABJRU5ErkJggg==")',
    boxShadow: 'inset 0 0 5px 0 rgb(0 0 0 / 10%)',
    minHeight: '60vh',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    width: '100%',
    flexDirection: 'column'
  },
  imageWrapper: {
    position: 'relative'
  },
  image: {
    position: 'relative'
  },
  imageToolbar: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    maxWidth: '40em',
    display: 'block',
  },
});

export const AssetEditForm: FC<any> = props => {
  const {record} = props;
  const classes = useStyles();
  const [preview, setPreview] = useState('medium');
  const imageRef = useRef(null);
  const newPointRef = useRef(null);
  const updateFocalPointCb = useRef(null);
  const [setupFocalPoint, setSetupFocalPoint] = useState(false);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [newFocalPoint, setNewFocalPoint] = useState(null);
  const handleChangePreview = (e) => {
    console.log("handleChangePreview", preview);
    setPreview(e.target.value);
  }
  const handleChangeFocalPoint = (x ,y) => {
    console.log("newX", {x, y})
    newPointRef.current = {x: x /100, y: y/100};
  }
  const handleCancelSetFocalPoint = () => {
    setSetupFocalPoint(false);
  }
  const handleSetFocalPoint = () => {
    updateFocalPointCb.current(newPointRef.current);
    setSetupFocalPoint(false);
    console.log("SetFocalPoint", newPointRef.current);
    setNewFocalPoint(newPointRef.current);
  }
  const handleEnableSetupFocalPoint = async (value, onChange) => {
    if(preview !== 'medium') {
      setPreview('medium')
    }
    console.log("handleEnableSetupFocalPoint", value);
    setNewFocalPoint(value);
    updateFocalPointCb.current = onChange;
    setTimeout(() => {



      setImageWidth(imageRef.current.width);
      setImageHeight(imageRef.current.height);
      setSetupFocalPoint(true);
    }, 400)


  }
  return (
    <Grid container>
      <Grid item xs={8}>
        <div className={classes.imageView}>
          <div className={classes.imageWrapper}>
            <div className={classes.image}>
          <img ref={imageRef} src={`${getMediaPath(record.source)}?preset=${preview}&fpx=${newFocalPoint?.x || record.focalPoint?.x || '0.5'}&fpy=${newFocalPoint?.y || record.focalPoint?.y || '0.5'}`} alt="" />
              {setupFocalPoint && <FocalPoint
            width={imageWidth}
            height={imageHeight}
            x={newFocalPoint?.x}
            y={newFocalPoint?.y}
            onChange={handleChangeFocalPoint}
          />}
            </div>
            {setupFocalPoint && <div className={classes.imageToolbar}>
              <IconButton aria-label="delete" onClick={handleCancelSetFocalPoint}>
                <CancelIcon />
              </IconButton>
              <Button variant="contained" color="primary" onClick={handleSetFocalPoint}>
                Установить
              </Button>
            </div>}
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
            <Box pl={3}>
         <TextInput
            source="name"
            label={'Название'}
            fullWidth
            helperText={false}
            variant={'outlined'}
            validate={requiredValidate}
          />
          <Box mt={0}>
          <Typography color={'textSecondary'} variant={'body2'}>
            Исходный файл:
          </Typography>
          <Link href={getMediaPath(record.source)} target={'_blank'}>
            Скачать файл
          </Link>
          </Box>

              <Box mt={2}>
        <FocalPointInput source={'focalPoint'} label={'Координаты центра:'} onSetup={handleEnableSetupFocalPoint}/>
              </Box>
        <Box mt={2}>
          <TextInput
            source="authorName"
            label={'Автор'}
            fullWidth
            helperText={false}
            variant={'outlined'}
          />
          {/*<ReferenceArrayInput {...props} label={'Теги'} source="tagsIds" reference="Tag"  perPage={100} sort={{ field: 'value', order: 'ASC' }}>
            <AutocompleteArrayInput optionText="value" optionValue={'value'} variant={'outlined'} fullWidth={true}/>
          </ReferenceArrayInput>*/}
          <SelectInput
            source="preview"
            fullWidth
            defaultValue={preview}
            label={'Размер'}
            helperText={'Для отображения слева'}
            onChange={handleChangePreview}
            variant={'outlined'}
            choices={[{id: 'tiny', name: 'Маленькое превью'},
              {id: 'thumb', name: 'Превью'},
              {id: 'small', name: 'Меленький'},
              {id: 'medium', name: 'Средний'},
              {id: 'large', name: 'Большой'},
              {id: 'fullSize', name: 'Оригинал'}]}
          />
        </Box>
            </Box>
      </Grid>
    </Grid>

  );
};
const requiredValidate = [required()];



 export const AssetEditModal = (props) => {
   const { data: record, loading, error } = useGetOne('asset', props.id);
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
    console.log("AssetEditModal", props)
  const handleSubmit = async data => {

    const res = await dataProvider.update('asset', {id: record.id, data, previousData: record});
    console.log("UpdateRes111", res);
    if(props.refresh) {
      refresh();
    }
    if(props.onClose){
      props.onClose(res.data || data);
    }
    return true;
  };

  return <ModalForm
    {...props}
    initiaValues={{preview: 'medium'}}
    fullWidth={true}
    maxWidth={'lg'}
    title={'Изменить файл'}
    handleClose={props.onClose}
    save={handleSubmit}

    record={record}

  >
    {record && <AssetEditForm/>}
  </ModalForm>
}
AssetEditModal.defaults = {
   refresh: true
}